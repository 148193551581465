// Save IP to local storage with expiry time
import {decryptData, encryptData} from "./crypto";

// Save IP and location to local storage with expiry
export const setIpAndLocationInLocalStorage = (data, expiryTimeInMinutes = 60) => {
    const expiryTimestamp = new Date().getTime() + expiryTimeInMinutes * 60 * 1000;
    const dataToStore = { ...data, expiry: expiryTimestamp };
    const encryptedData = encryptData(dataToStore);
    if (typeof window !== 'undefined') {
        localStorage.setItem('encryptedIpLocation', encryptedData);
    }
};

// Get IP and location from local storage if valid
export const getIpAndLocationFromLocalStorage = () => {
    const encryptedData = localStorage.getItem('encryptedIpLocation');
    if (!encryptedData) return null;

    const decryptedData = decryptData(encryptedData);
    if (!decryptedData) return null;

    const { expiry } = decryptedData;
    const currentTime = new Date().getTime();

    // If expired, remove the data from localStorage
    if (currentTime > expiry) {
        if (typeof window !== 'undefined') {
            localStorage.removeItem('encryptedIpLocation');
        }
        return null;
    }

    return decryptedData;
};

export const setToken = (token, expires = null) => {

    if (typeof window !== 'undefined') {
        localStorage.setItem('token', token);
        localStorage.setItem('token_expires', expires);
    }
};

export const removeToken = () => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expires');
    }
};

export const getToken = () => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('token');
    }
    return null;
};

export const getTokenExpires = () => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('token_expires');
    }

    return null;
};