import {CohesiiContentComponent} from "../../../lib";
import Breadcrumbs from "../Breadcrumbs";
import LatestListWidget from "./LatestListWidget";
import SEO from "../../../Seo";
import React from "react";
import {useLocation} from "react-router-dom";


import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";


const Post = ({data}) => {

    const locationData = useLocation();

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {title, block_content, excerpt, featured_asset, slug} = data
    const date = new Date(data?.created_at);

// Use Intl.DateTimeFormat to format the date as "day month year"
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }).format(date);

    const seoTitle = (data?.seo_title ?? title) + ' | Blog';
    const meta_description = data?.seo_description || data?.excerpt

    const shareButtons = (border = "t") => {
        return (
            <div className={`flex flex-col border-${border}-[2px] border-${border}-gray-100 mt-6 mb-6`}>
                <div className={'flex self-end gap-1 mt-4'}>
                    <LinkedinShareButton source={process.env.REACT_APP_URL + locationData.pathname} summary={excerpt} title={title}
                                         url={process.env.REACT_APP_URL + locationData.pathname}>
                        <LinkedinIcon size={32} round={false}/>
                    </LinkedinShareButton>
                    <RedditShareButton title={title}
                                       url={process.env.REACT_APP_URL + locationData.pathname}>
                        <RedditIcon size={32} round={false}/>
                    </RedditShareButton>
                    <TwitterShareButton title={title}
                                        hashtags={["WeAreCohesii", "Cohesii"]}
                                        url={process.env.REACT_APP_URL + locationData.pathname}
                                        related={["cohesiiltd"]}
                    >

                        <TwitterIcon size={32} round={false}/>
                    </TwitterShareButton>
                    <WhatsappShareButton title={title}
                                         url={process.env.REACT_APP_URL + locationData.pathname}
                    >

                        <WhatsappIcon size={32} round={false}/>
                    </WhatsappShareButton>
                </div>
            </div>

         )
    }

    return (
        <>
            <SEO
                title={seoTitle}
                description={meta_description}
                url={process.env.REACT_APP_URL + locationData.pathname}
                canonical={process.env.REACT_APP_URL + locationData.pathname}
                image={featured_asset ? baseUrl + featured_asset : process.env.REACT_APP_URL + '/images/cohesii-blog.png'}
                publishDate={date}
                imageAlt={title}
                pageType={'article'}
                breadcrumbs={[
                    {path: process.env.REACT_APP_URL, label: 'Home'},
                    {path: process.env.REACT_APP_URL + '/blog', label: 'Blog'},
                    {path: process.env.REACT_APP_URL + `/blog/${slug}`, label: title},
                ]}
            />

            <section className={'cms_post__header'}>
                <div className={'container'}>
                    <p className={'cms_post__published'}>Published {formattedDate}</p>
                    <h1 className={'cms_post__header_title'}>{title}</h1>
                    <p className={'cms_post__excerpt'}>{excerpt}</p>
                </div>
            </section>
            <section className={'cms_post__content'}>
                <div className={'container !pt-0 py-8'}>
                    <Breadcrumbs crumbs={[
                        {path: process.env.REACT_APP_URL, label: 'Home'},
                        {path: process.env.REACT_APP_URL + '/blog', label: 'Blog'},
                        {path: process.env.REACT_APP_URL + `/blog/${slug}`, label: title},
                    ]}/>
                </div>
                <div className={'container'}>
                    <figure>
                        <img src={baseUrl + featured_asset} alt={title}/>
                    </figure>

                    {
                        block_content.map((nested, index) => (
                            <CohesiiContentComponent key={nested.data._uid || index} content={nested}/>
                        ))
                    }
                    {shareButtons()}
                </div>


            </section>

            <section id={"latest_posts"} className={'latest-blogs  order-10'}>

                <div className={'container row'}>
                    <div className={'column justify-center'}>
                        <div className={'title-container mb-6'}>
                        <p className={'strapline'}>Latest Blogs</p>
                            <h4 className={'title'}>Keep up to date with the latest news from Cohesii</h4>
                        </div>



                        <LatestListWidget limit={4}/>
                    </div>
                </div>


            </section>
        </>
    )
}

export default Post