import {Link, useNavigate} from "react-router-dom";
import React from "react";

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

const ListItem = ({item, showFooter=true}) => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {featured_asset, featured_asset_grid, title, excerpt} = item

    const image = baseUrl + (featured_asset_grid ?? featured_asset)
    const date = new Date(item?.created_at);

// Use Intl.DateTimeFormat to format the date as "day month year"
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }).format(date);

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/blog/${item?.slug}`);
    };

    const shareButtons = (border = "t") => {
        return (
            <div className={`flex flex-row border-${border}-[2px] border-${border}-gray-100 mt-6 mb-6 items-center pt-4`}>
                <Link to={`/blog/${item?.slug}`} className={'flex-1 items-center font-bold text-gray-800 hover:text-blaze-500'}>
                    Read More
                </Link>
                <div className={'flex self-end gap-1 items-center'}>
                    <LinkedinShareButton source={window.location} summary={excerpt} title={title}
                                         url={window.location}>
                        <LinkedinIcon size={24} round={false}/>
                    </LinkedinShareButton>
                    <RedditShareButton title={title}
                                       url={window.location}>
                        <RedditIcon size={24} round={false}/>
                    </RedditShareButton>
                    <TwitterShareButton title={title}
                                        hashtags={["WeAreCohesii", "Cohesii"]}
                                        url={window.location}
                                        related={["cohesiiltd"]}
                    >

                        <TwitterIcon size={24} round={false}/>
                    </TwitterShareButton>
                    <WhatsappShareButton title={title}
                                         url={window.location}
                    >

                        <WhatsappIcon size={24} round={false}/>
                    </WhatsappShareButton>
                </div>
            </div>

        )
    }

    return (
        <article id={'post_'+item.id} className={'cms_post_list_item'}>
            <Link to={`/blog/${item?.slug}`} title={item?.name}>
                <figure>
                    <img  height={350} loading="lazy" src={ image} alt={title}/>
                </figure>
            </Link>
            <div className={"cms_post_list_item__content group hover:cursor-pointer"} onClick={handleClick}>
                <p className={'cms_post_list_item__published'}>Published {formattedDate}</p>
                <h4 className={"cms_post_list_item__content_title group-hover:text-blaze-500"}>
                    {title}
                </h4>
                <p className={'cms_post_list_item__content_except'}>
                    {excerpt}
                </p>

            </div>
            {showFooter && shareButtons()}
        </article>
    )

}
export default ListItem