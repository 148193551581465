
import Legal from "../../Layouts/Legal";
import Banner from "../../Simple/Banner";
import SEO from "../../../Seo";
import {useLocation} from "react-router-dom";
import {lazy, Suspense} from "react";
import Loading from "../Loading";

const TermsContent = lazy(() => import('./Content/TermsContent'));


const TermsAndConditions = () => {

    const location = useLocation();

    const content = {
        title: 'Terms and conditions',
        content: "Cohesii LTD is committed to preventing modern slavery and human trafficking in our operations and supply chain. Learn more about our policies, employee training, and reporting procedures to uphold ethical business practices.",
        "additional-content": [],
        asset: 'pexels-pixabay-39584 (1).jpg',
        _uid: "cookie-policy-header"
    }

    const title = "Terms and conditions"
    const meta_desc = "Cohesii LTD is committed to preventing modern slavery and human trafficking in our operations and supply chain. Learn more about our policies, employee training, and reporting procedures to uphold ethical business practices."

    let crumbs = [
        {
            'label': "Home",
            'path': process.env.REACT_APP_URL
        },
        {
            'label': title,
            'path': process.env.REACT_APP_URL + location.pathname
        },
    ]

    return (
        <>
            <SEO
                title={title}
                description={meta_desc}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={process.env.REACT_APP_URL + location.pathname }
                image={process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={'Cohesii strap line and logo over a blue gradient overlay with a commercial space background.'}
                breadcrumbs={crumbs}
            />
            <Banner content={content} />
            <Legal className={"order-2"}>

                <Suspense fallback={<Loading isLoading={true} />}>
                    <TermsContent />
                </Suspense>
            </Legal>
        </>
    )
}

export default TermsAndConditions