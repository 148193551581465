import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const GallerySlider = ({ baseUrl, images, startingIndex = 0 }) => {
    const [index, setIndex] = useState(startingIndex);

    useEffect(() => {
        setIndex(startingIndex);
    }, [startingIndex]);

    const transitions = useTransition(index, {
        key: index,
        from: { transform: 'translate3d(100%,0,0)' },
        enter: { transform: 'translate3d(0%,0,0)' },
        leave: { transform: 'translate3d(-100%,0,0)' },
        config: { duration: 500 },
    });

    const nextSlide = () => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="slider-container">
            <div className="slider">
                {transitions((style, i) => (
                    <animated.div className="slide" style={style}>
                        <img loading={"lazy"} src={baseUrl+images[i]} alt={`Slide ${i}`} />
                    </animated.div>
                ))}
            </div>
            <FontAwesomeIcon icon="fa-solid fa-chevron-left" onClick={prevSlide} className=" size-10 slider-button prev" />
            <FontAwesomeIcon icon="fa-solid fa-chevron-right" onClick={nextSlide} className="size-10 slider-button next" />
        </div>
    );
};

export default GallerySlider;