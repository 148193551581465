import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show the button when the user scrolls down a certain distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll to the top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <div>
            {isVisible && (
                <div onClick={scrollToTop} className="scroll-to-top">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-up" />
                </div>
            )}
        </div>
    );
};

export default ScrollToTop;