import React from 'react';
import { Provider } from 'react-redux';
import CookieConsentComponent from './lib/CookieConsent';
import { ModalProvider } from './lib/Modal/ModalContext';
import store from './app/store';
import './index.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'react-international-phone/style.css'
import {RouterProvider} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const App = ({router, ssr=false}) => {

    // Client-side effects: Dispatch CSRF token, set up icons, and initialize content

    return (
        <HelmetProvider>
            <Provider store={store}>
                <CookieConsentComponent>
                    <ModalProvider>
                        <RouterProvider router={router} />
                    </ModalProvider>
                </CookieConsentComponent>
            </Provider>
        </HelmetProvider>
    );
};

export default App;