const Pagination = ({ pagination, handlePageChange }) => {

    const { current_page, total_pages } = pagination;

    // Helper function to generate pagination buttons
    const renderPageNumbers = () => {
        let pages = [];
        const maxPagesToShow = 5;
        const halfMaxPages = Math.floor(maxPagesToShow / 2);

        // Determine the start and end pages to display
        let startPage = Math.max(1, current_page - halfMaxPages);
        let endPage = Math.min(total_pages, current_page + halfMaxPages);

        if (current_page <= halfMaxPages) {
            // When near the start
            endPage = Math.min(total_pages, maxPagesToShow);
        } else if (current_page + halfMaxPages >= total_pages) {
            // When near the end
            startPage = Math.max(1, total_pages - maxPagesToShow + 1);
        }

        // Add the "Previous" button
        if (current_page > 1) {
            pages.push(
                <div className={'previous-wrap flex-1 flex justify-start'}>
                    <button
                        key="prev"
                        onClick={() => handlePageChange(current_page - 1)}
                        className="prev"
                    >
                        <svg
                            className="shrink-0 size-3.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="m15 18-6-6 6-6"></path>
                        </svg>
                        <span className="hidden sm:block">Previous</span>
                    </button>
                </div>
            );
        }else {
            pages.push(
                <div className={'previous-wrap flex-1 flex justify-start'}>
                </div>
            )
        }

        // If there are pages before the current set
        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    onClick={() => handlePageChange(1)}
                    className={`pagination-button ${
                        current_page === 1 ? "bg-gray-200" : ""
                    }`}
                >
                    1
                </button>
            );
            if (startPage > 2) {
                pages.push(<span key="ellipsis1">...</span>);
            }
        }

        // Add page numbers in the range of startPage to endPage
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`pagination-button ${
                        current_page === i ? "active" : ""
                    }`}
                    aria-current={current_page === i ? "page" : undefined}
                >
                    {i}
                </button>
            );
        }

        // If there are pages after the current set
        if (endPage < total_pages) {
            if (endPage < total_pages - 1) {
                pages.push(<span key="ellipsis2">...</span>);
            }
            pages.push(
                <button
                    key={total_pages}
                    onClick={() => handlePageChange(total_pages)}
                    className={`pagination-button ${
                        current_page === total_pages ? "bg-gray-200" : ""
                    }`}
                >
                    {total_pages}
                </button>
            );
        }

        // Add the "Next" button
        if (current_page < total_pages) {
            pages.push(
                <div className={'next-wrap flex-1 justify-end'}>
                    <button
                        key="next"
                        onClick={() => handlePageChange(current_page + 1)}
                        className="next"
                    >
                        <span className="hidden sm:block">Next</span>
                        <svg
                            className="shrink-0 size-3.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="m9 18 6-6-6-6"></path>
                        </svg>
                    </button>
                </div>
            );
        }else {
            pages.push(
                <div className={'next-wrap flex-1 justify-end'}>
                </div>
            )
        }

        return pages;
    };

    return (
        <nav className="pagination" aria-label="Pagination">
            <div className="flex items-center justify-center flex-1 gap-x-1">{renderPageNumbers()}</div>
        </nav>
    );
};

export default Pagination;