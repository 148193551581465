// src/hooks/useSessionTracking.js
import { useEffect, useState } from 'react';
import analyticsHelper from '../app/helpers/analytics';
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import Bowser from "bowser";

const SESSION_ID_KEY = 'session_id';

const useSessionTracking = (userId, listingId) => {
    const [sessionId, setSessionId] = useState(() => {
        // Get the session ID from local storage or generate a new one
        return localStorage.getItem(SESSION_ID_KEY) || generateSessionId();
    });

    useEffect(() => {
        // Store the session ID in local storage
        localStorage.setItem(SESSION_ID_KEY, sessionId);
    }, [sessionId]);


    const getDeviceInfo = () => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        return {
            browser: browser.getBrowserName(),
            os: browser.getOSName(),
            deviceType: isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop'
        };
    };

    // Function to track a session
    const trackSession = async () => {
        try {
            let payload = {
                user_id: userId.toString(),
                session_id: sessionId.toString(),
                new_listing_id: listingId.toString(),
                referrer: document.referrer,
                device_info: getDeviceInfo()
            }
            await analyticsHelper.trackSession(payload); // Include listingId
        } catch (error) {
           //
        }
    };

    // Function to track an event
    const trackEvent = async (eventType, additionalData) => {
        try {
            await analyticsHelper.trackEvent(sessionId, eventType, additionalData);
        } catch (error) {
           //
        }
    };

    // Only call trackSession when userId and sessionId are ready
    useEffect(() => {
        if (userId && listingId) {
            trackSession();
        }
    }, [userId, listingId, sessionId]); // Track session when userId, listingId, or sessionId changes

    return { sessionId, trackSession, trackEvent }; // Return sessionId, trackSession function, and trackEvent function
};

// Function to generate a unique session ID
const generateSessionId = () => {
    return 'session_' + new Date().getTime() + '_' + Math.random().toString(36).substr(2, 9);
};

export default useSessionTracking;