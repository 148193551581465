import {contentBuilderInit} from "../../lib";
import Page from "../../features/Layouts/Page";
import {
    BannerComponent, BannerRegisterComponent, BannerWithContentComponent, ButtonComponent, CodeComponent,
    ColumnComponent, FaqComponent, FaqsComponent, IconComponent, IconGroupComponent,
    ImageComponent, ImageCtaComponent, NumberedTimelineComponent,
    RowComponent,
    SectionComponent,
    TitleComponent, TwoColumnTextImageComponent
} from "../../features/Simple";

import ContactPage from "../../features/Components/Contact/ContactPage";
import SpaceActivityWidget from "../../features/Components/SpaceActivityWidget";
import ContentCenter from "../../features/Simple/ContentCenter";
import Cta from "../../features/Simple/Cta";
import LatestBlogs from "../../features/Simple/LatestBlogs";


export const initContentBuilder = async () => {
    contentBuilderInit({
        apiKey: 'Null',
        types: {
            'page': Page
        },
        components: {
            'section': SectionComponent,
            'banner': BannerComponent,
            'row': RowComponent,
            'column': ColumnComponent,
            'image': ImageComponent,
            'asset': ImageComponent,
            'title': TitleComponent,
            'button': ButtonComponent,
            '2-column-text-image': TwoColumnTextImageComponent,
            'image_cta': ImageCtaComponent,
            'contact_form': ContactPage,
            'icon-group-icon': IconComponent,
            'banner_content_area': BannerWithContentComponent,
            'faqs': FaqsComponent,
            'faq': FaqComponent,
            'code': CodeComponent,
            'icon-group': IconGroupComponent,
            'banner_register': BannerRegisterComponent,
            'numbered_timeline': NumberedTimelineComponent,
            'activity_row': SpaceActivityWidget,
            'content_center': ContentCenter,
            'cta': Cta,
            'latest_blogs': LatestBlogs
        }
    })
}