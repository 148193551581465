import {useEffect, useState} from "react";
import {useGetLatestMutation} from "../../../app/services/blogApi";
import Loading from "../Loading";
import ListItem from "./ListItem";

const LatestListWidget = ({limit = 4}) => {

    const [getLatest, {isLoading}] = useGetLatestMutation()
    const [posts, setPosts] = useState([])

    const getLatestRequest = async () => {
        if (!isLoading) {
            const latest = await getLatest({
                catId: null,
                limit: limit
            })

            if (latest?.data?.data) {
                setPosts(latest?.data?.data)
            }

        }
    }
    useEffect(() => {
        getLatestRequest()
    }, []);


    return (
        <>
            <Loading isLoading={isLoading}/>

            {/** lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 **/}
            <div className={`grid grid-cols-1 lg:grid-cols-${limit} gap-6`}>

                {!isLoading &&
                    posts?.map((post, index) => (
                        <ListItem item={post} key={'post_' + post.id} showFooter={false}/>

                    ))
                }


            </div>

        </>
    )
}

export default LatestListWidget;