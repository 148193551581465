import React, {forwardRef, useCallback, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import Loading from "../Loading";
import {useLoadActivityCats} from "../../../hooks/useBaseData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const FindASpaceMegaMenu = forwardRef(({ isHidden, isOpen, onToggle }, ref) => {

    const menuRef = useRef(null);

    const {activityCats, isLoading}= useLoadActivityCats()

    const limited = !isLoading && Array.isArray(activityCats) ? activityCats.slice(1, 5) : [];

    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            if (isOpen) {
                onToggle();
            }
        }
    }, [menuRef, onToggle]);

    useEffect(() => {
        if (isHidden && isOpen) {
            onToggle();
        }
    }, [isHidden, isOpen, onToggle]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onToggle();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
            <li  ref={menuRef}>
                <span
                    className="group cursor-pointer"
                    onClick={onToggle}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    role="button"
                    aria-expanded={isOpen}
                >
                    Find a space
                    {!isOpen && <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                    {isOpen && <FontAwesomeIcon icon="fa-solid fa-chevron-up"  className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                </span>

                {isOpen && (
                    <div className="mega-menu_dropdown_container">
                        <div className="container mx-auto p-0  md:p-4">
                            <div className="mega-menu__column_container !grid-cols-4">
                                <Loading isLoading={isLoading && !limited} />
                                {(!isLoading && limited) && limited.map((item, index) => (
                                <div className={'mega-menu__column'}  key={'parent_menu_' + item?.id}>
                                    <h3 className={'title'}>{item?.name}</h3>
                                    {item?.subcategories?.slice(1,5).map((child, index) => (
                                        <Link key={'child_menu_' + child?.id} to={`/spaces/activities/${child.slug}`} title={child?.description}>
                                            <p className={'link'}>{child?.name}</p>
                                            <p dangerouslySetInnerHTML={{__html: child?.description}} />
                                        </Link>
                                    ))}
                                </div>
                                ))}
                                <div className={'col-span-4 text-right flex-1'}>
                                    <Link to={'/spaces/activities'} title={'All space activities'} className={'btn-primary mt-6 !text-xl'}>
                                        See all space activities
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </li>
    );
});

