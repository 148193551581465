import {forwardRef, useEffect, useState} from "react";
import {SolutionsMegaMenu} from "./SolutionsMenu";
import {ResourcesMegaMenu} from "./ResourcesMenu";
import {FindASpaceMegaMenu} from "./Findaspace";
import {useLocation} from "react-router-dom";



const Menu = forwardRef(({isHidden}, ref) => {

    const [openMenu, setOpenMenu] = useState(null);

    const handleMenuToggle = (menuName) => {
        setOpenMenu(openMenu === menuName ? null : menuName);
    };

    const location = useLocation();

    useEffect(() => {
        setOpenMenu(null)
    }, [location]);

    return (
        <nav className={'main-menu '}>
            <ul>
                <FindASpaceMegaMenu
                    isHidden={isHidden}
                    ref={ref}
                    isOpen={openMenu === 'find-a-space'}
                    onToggle={() => handleMenuToggle('find-a-space')}
                />
                <SolutionsMegaMenu
                    isHidden={isHidden}
                    ref={ref}
                    isOpen={openMenu === 'solutions'}
                    onToggle={() => handleMenuToggle('solutions')}
                />
                <ResourcesMegaMenu
                    isHidden={isHidden}
                    ref={ref}
                    isOpen={openMenu === 'resources'}
                    onToggle={() => handleMenuToggle('resources')}
                />


            </ul>

        </nav>
    )


})

export default Menu