const FacilityItem = ({facility}) => {
    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {name, icon_path, description = ''} = facility
    return (
        <div className={'facilities__item'}>
            <h5 className={'facilities__item__title'}>

                <img className={'w-8 h-8'} src={`${baseUrl}${icon_path}`} alt={name} title={name} />
                {name}
            </h5>
            <div className={'facilities__item__desc'}>
                <div dangerouslySetInnerHTML={{__html: description}} />
            </div>
        </div>
    )
}

const Facilities = ({facilities, facilityRef}) => {
    const title_map = {
        'additional_cost': 'Available at an additional cost',
        'building' : 'Included within the building',
        'space' : 'Included within the space',
    }
    return (
        <section className={'facilities'} ref={facilityRef}>
            <h3 className={'facilities__header_title'}>Facilities</h3>
            {facilities && Object.entries(facilities).map(([type, facilitiesArray]) => (
                <div id={type} key={type} className="facility">
                    <div className={'facility__header'}>
                        <h3 className={'facility__header_title'}>{title_map[type]}</h3>
                        <div className={'facility__grid'}>
                            {facilitiesArray && facilitiesArray.map((facility, index) => (
                                <FacilityItem facility={facility} key={facility.id}/>
                            ))}
                        </div>
                    </div>
                </div>
            ))}


        </section>
    )


}

export default Facilities