import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        // If there's a hash (anchor), scroll to the corresponding element
        if (hash) {
            const element = document.getElementById(hash.slice(1)); // Remove the '#' character
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Scroll to the top when there's no anchor (hash)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [pathname, hash]);  // Re-run when either the pathname or hash changes

    return null;  // This component doesn't render anything
};

export default useScrollToTop;