import {AdvancedMarker, APIProvider, Map, useMap} from "@vis.gl/react-google-maps";
import React, {useEffect} from "react";

const MapView =({data}) => {

    const map = useMap();
    const {meta} = data
    const {latitude:lat, longitude:lng} = meta;


    useEffect(() => {
        if (map) {
            map.setCenter({lat: parseFloat(lat), lng: parseFloat(lng)});
        }
    }, [map, lat, lng]);

    return (
        <APIProvider apiKey={"AIzaSyAtBe919tk4J1owu_W49rsdE1DqXmuXQgc"}>
            <Map
                className={'w-full h-[300px]'}
                defaultCenter={{lat: parseFloat(lat), lng: parseFloat(lng)}}
                defaultZoom={15}
                disableDefaultUI={true}
                gestureHandling={'none'}
                controlled={false}
                mapId={'space'}
                mapTypeId={'roadmap'}
            >
                <AdvancedMarker position={{lat: parseFloat(lat), lng: parseFloat(lng)}}  >
                    <div
                        className="h-10 w-10 bg-blaze-500 rounded-full flex justify-center items-center animation-pulse opacity-80">

                    </div>
                </AdvancedMarker>
            </Map>
        </APIProvider>
    )
}

export default MapView