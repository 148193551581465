import Legal from "../../Layouts/Legal";
import Banner from "../../Simple/Banner";
import {useLocation} from "react-router-dom";
import SEO from "../../../Seo";
import {lazy, Suspense} from "react";
import Loading from "../Loading";

const PrivacyPolicyContent = lazy(() => import('./Content/PrivacyPolicyContent'));


const PrivacyPolicy = () => {

    const location = useLocation();

    const content = {
        title: 'Privacy Policy',
        content: "Read our Privacy Policy to understand how we collect, use, and protect your personal information. Learn about your rights and how we ensure data privacy and security in compliance with applicable laws.",
        "additional-content": [],
        asset: 'pexels-pixabay-39584 (1).jpg',
        _uid: "privacy-policy-header"
    }

    const title = "Privacy Policy"
    const meta_desc = "Read our Privacy Policy to understand how we collect, use, and protect your personal information. Learn about your rights and how we ensure data privacy and security in compliance with applicable laws."

    let crumbs = [
        {
            'label': "Home",
            'path': process.env.REACT_APP_URL
        },
        {
            'label': title,
            'path': process.env.REACT_APP_URL + location.pathname
        },
    ]
    return (
        <>
            <SEO
                title={title}
                description={meta_desc}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={process.env.REACT_APP_URL + location.pathname }
                image={process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={'Cohesii strap line and logo over a blue gradient overlay with a commercial space background.'}
                breadcrumbs={crumbs}
            />
            <Banner content={content} />
            <Legal className={"order-2"}>
                <Suspense fallback={<Loading isLoading={true} />}>
                    <PrivacyPolicyContent />
                </Suspense>
            </Legal>
        </>
    )
}

export default PrivacyPolicy