import {useFilterSpacesMutation} from "../../../app/services/spacesApi";
import React, {useEffect} from "react";
import ListItem from "./ListItem";
import CategoryFilter from "./CategoryFilter";
import {useLocation, useSearchParams} from "react-router-dom";
import Loading from "../Loading";
import Pagination from "../Blog/Pagination";
import SEO from "../../../Seo";

const SpacesList = () => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const [filterSpaces, {isLoading, isFetching, data}] = useFilterSpacesMutation()


    const location = useLocation();
    const searchParamsString = location.search;

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        if (!isLoading) {
            filterSpaces({params: searchParamsString})
        }
    }, [searchParamsString]);  // eslint-disable-next-line



    const updateParam = (param, value) => {
        // Create a new URLSearchParams object from the current search params
        const updatedParams = new URLSearchParams(searchParams);

        if (param !== 'page'){
            updatedParams.set('page', 1);
        }
        // Set the new parameter or update it
        updatedParams.set(param, value);

        // Update the search params in the URL
        setSearchParams(updatedParams);
    };

    const handlePageChange = (newPage) => {
        updateParam('page', newPage)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const buildText = () => {

        let radius = searchParams.get('radius');
        let location = searchParams.get('location');
        let base = (data?.pagination?.total_items > 1 ? 'spaces': 'space') + ' available'
        if (location) {
            base += ` in ${radius ?? 30} mile radius of ${location}`
        }

        return base
    }

    const {current_page, total_pages } = data?.pagination || {}

    return (
        <div className="spaces-list">

            <SEO
                title={"Find a space"}
                description={"Find spaces suitable for your activity and needs."}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={process.env.REACT_APP_URL + '/find-a-space'}
                image={process.env.REACT_APP_URL + '/images/cohesii.png'}
                imageAlt={"Find a space"}
                breadcrumbs={[
                    {path: process.env.REACT_APP_URL, label: 'Home'},
                    {path: process.env.REACT_APP_URL + '/find-a-space', label: 'Find a space'},
                ]}
                nextPage={process.env.REACT_APP_URL + `/find-a-space?page=${current_page > 1 ? current_page + 1 : 1}`}
                prevPage={process.env.REACT_APP_URL + `/find-a-space?page=${current_page +1 < total_pages ? current_page - 1 : total_pages}`}
            />


            <div className={'spaces-list__header'}>
                <CategoryFilter baseUrl={baseUrl} catData={data?.categories} selected={searchParams.get('activity')}
                                handleFilter={updateParam}/>
            </div>
            <section className="spaces-list__lis_con container">

                <Loading isLoading={isLoading || isFetching}/>
                {!isFetching && (
                    <>

                        {data?.data && (
                            <div className={'column'}>
                                <p className={'mb-2 text-base text-gray-500'}>{data?.pagination?.total_items} {buildText()} </p>
                                <div className={'spaces-list__list'}>
                                    {data?.data.map((space) => (
                                        <ListItem baseUrl={baseUrl} key={space.id} space={space}/>
                                    ))}

                                    {data?.data?.length < 1 && (
                                        <div className={"spaces-list__no-results"}>

                                            <div className={'comming-soon col-span-4 flex flex-col gap-4'}>
                                                <h3 className={'title w-full justify-center items-center'}>We couldn't
                                                    find
                                                    any spaces for {searchParams.get('activity') ?? ''}.</h3>

                                                <p>We’re constantly working hard to bring you new spaces so come back
                                                    soon to find your next adventure.</p>

                                                <p>In the meantime, feel free to explore other sections of our
                                                    marketplace, and remember, new
                                                    spaces are just around the corner!</p>

                                            </div>

                                        </div>
                                    )}
                                </div>

                                {data?.data?.length > 1 &&
                                    <Pagination handlePageChange={handlePageChange} pagination={data?.pagination}/>}
                            </div>
                        )}


                    </>
                )}
            </section>
        </div>
    )
}

export default SpacesList

