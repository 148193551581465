import {useDispatch, useSelector} from "react-redux";
import {useGetCategoriesTreeQuery, useGetFeaturedQuery} from "../app/services/spacesApi";
import {useEffect} from "react";
import {setActivityCats, setFeaturedActivity} from "../app/features/data/dataSlice";

export const useLoadActivityCats = () => {
    const dispatch = useDispatch();
    const activityCats = useSelector((state) => state.data.activityCats);


    // Use RTK Query hook to fetch activity categories
    const { data, isLoading } = useGetCategoriesTreeQuery(
        { limit: null },
        { skip: !!activityCats } // skip the query if we already have data
    );

    // Store data in Redux state when fetched
    useEffect(() => {
        console.log(data)
        if (data && !activityCats) {

            dispatch(setActivityCats(data.data));
        }
    }, [data]);

    return { activityCats, isLoading };
};


export const useLoadFeaturedCats = () => {
    const dispatch = useDispatch();
    const featuredActivity = useSelector((state) => state.data.featuredActivity);

    // Use RTK Query hook to fetch activity categories
    const { data, isLoading } = useGetFeaturedQuery(
        null,
        { skip: !!featuredActivity } // skip the query if we already have data
    );


    // Store data in Redux state when fetched
    useEffect(() => {
        if (data && !featuredActivity) {
            dispatch(setFeaturedActivity(data));
        }
    }, [data, featuredActivity]);

    return { featuredActivity, isLoading };
};
