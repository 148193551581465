import React from 'react'
import {createBrowserRouter, createMemoryRouter, redirect} from "react-router-dom";
import ErrorBoundary from "../../ErrorBoundary";
import axios from "axios";
import Page from "../../features/Layouts/Page";
import PostType from "../../features/Components/Blog/PostType";
import Activities from "../../features/Components/ListSpaces/Activities";
import Category from "../../features/Components/ListSpaces/Category";
import Blog from "../../features/Components/Blog/Blog";
import Root from "../../features/Layouts/Root";
import SpacesList from "../../features/Components/ListSpaces/SpacesList";
import PrivacyPolicy from "../../features/Components/Legal/PrivacyPolicy";
import CookiePolicy from "../../features/Components/Legal/CookiePolicy";
import TermsAndConditions from "../../features/Components/Legal/TermsAndConditions";
import ModernSlaveryPolicy from "../../features/Components/Legal/ModernSlaveryPolicy";
import Space from "../../features/Components/ViewSpaces/Space";

const HomeLoader = async () => {
    try {
        // Example: Fetch routes from multiple APIs
        const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/content/home`); // Replace with your API endpoints
        return api.data
    } catch (err) {
        throw new Response("Not Found", { status: 404 });
    } finally {
    }
}

const ContentLoader = async ({params}) => {
    let slug = params["*"];

    if (!slug) {
        slug = 'home'
    }
    console.log(`${process.env.REACT_APP_BASE_API_URL}/content/${slug}`)
    try {
        // Example: Fetch routes from multiple APIs
        const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/content/${slug}`); // Replace with your API endpoints

        return api.data
    } catch (err) {
        throw new Response("Not Found", { status: 404 });
    } finally {
    }
}

const BlogObjectLoader = async ({params}) => {
    const slug = params["route"];
    try {
        // Example: Fetch routes from multiple APIs
        const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/blog/${slug}`); // Replace with your API endpoints
        return api.data
    } catch (err) {
        throw new Response("Not Found", { status: 404 });
    } finally {
    }
}

const ActivityLoader = async ({params}) => {
    const slug = params["slug"];
    try {
        // Example: Fetch routes from multiple APIs
        const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/spaces/categories/${slug}`); // Replace with your API endpoints
        const {data} = api

        if (data.success) {
            return data.data
        }
    } catch (err) {
        throw new Response("Not Found", { status: 404 });
    } finally {
    }
}

// Lazy load the components

export const routes =  [
    {
        path: "/",
        element: <Root />,
        errorElement:<ErrorBoundary />,
        children: [
            {
                element: <Page />,
                loader: HomeLoader,
                index: true,
            },
            {
                path: "find-a-space",
                element: <SpacesList />,
            },
            {
                path: "privacy-policy",
                element: <PrivacyPolicy />,
            },
            {
                path: "cookie-policy",
                element: <CookiePolicy />,
            },
            {
                path: "terms-and-conditions",
                element: <TermsAndConditions />,
            },
            {
                path: "anti-slavery-policy",
                element: <ModernSlaveryPolicy />,
            },
            {
                path: "spaces",
                children: [
                    {
                        loader: async () => redirect("activities"),
                        index: true,
                    },
                    {
                        path: "activities",
                        element: <Activities />,
                    },
                    {
                        path: "activities/:slug",
                        element: <Category />,
                        loader: ActivityLoader,
                    },
                    {
                        path: ":slug",
                        element: <Space isServer={false} />
                    }

                ]
            },
            {
                path: "blog",
                children: [
                    {
                        path: "",
                        element: <PostType />
                    },
                    {
                        path: ":route",
                        element: <Blog />,
                        loader: BlogObjectLoader
                    },
                ]
            },
            {
                path: "*",
                element: <Page />,
                loader: ContentLoader
            }
        ],
    },
];


export const createRouter = (isServer = false, req = null) => {

    if (isServer && req) {
        return createMemoryRouter(routes, { initialEntries: [req.url] });   // Server-side static router
    }
    return createBrowserRouter(routes);  // Client-side browser router
};
