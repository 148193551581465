import React from 'react';
import {createRoot} from 'react-dom/client';

import App from "./app";
import {createRouter} from "./app/helpers/Router";
import {hydrate} from "react-dom";

const rootElement =   document.getElementById('root')


if (rootElement.hasChildNodes()) {
    // This means we are hydrating the app from SSR
    hydrate(<App router={createRouter(false)} />, rootElement);
} else {
    // This means we are starting a fresh React app in development mode
    const root = createRoot(rootElement);

    // Create a RouterProvider based on your application needs
    root.render(
        <App router={createRouter(false)}/>
    );
}