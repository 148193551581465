import React, {useState} from "react";
import BlogPostList from "./List";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import Pagination from "./Pagination";
import Breadcrumbs from "../Breadcrumbs";
import Loading from "../Loading";
import SEO from "../../../Seo";

const Category = ({category}) => {

    const locationData = useLocation();
    const baseUrl = process.env.REACT_APP_STORAGE_URL;

    const [searchParams, setSearchParams] = useSearchParams();
    const [cats, setCats] = useState([]);
    const [pagination, setPagination] = useState({});

    const handlePageChange = (newPage) => {
        // Update the query parameter
        setSearchParams({ p: newPage });
    };

    const seoTitle = (category?.seo_name || category?.name) + ' | Blog';
    const meta_description = category?.seo_description || category?.description

    return (
        <div className={'cms__blog'}>
            <SEO
                title={seoTitle}
                description={meta_description}
                url={process.env.REACT_APP_URL + locationData.pathname}
                canonical={process.env.REACT_APP_URL + locationData.pathname}
                image={category?.featured_asset ? baseUrl + category?.featured_asset : process.env.REACT_APP_URL+'/images/cohesii-blog.png'}
                imageAlt={seoTitle}
                breadcrumbs={[
                    {path: process.env.REACT_APP_URL, label: 'Home'},
                    {path: process.env.REACT_APP_URL+'/blog', label: 'Blog'},
                    {path: process.env.REACT_APP_URL+`/blog/${category?.slug}`, label: category?.name},
                ]}
            />

            <section className={'cms_post__header'}>
                <div className={'container'}>
                    <p className={'cms_post__published'}>Cohesii Blog</p>
                    <h1 className={'cms_post__header_title'}>{category?.name}</h1>
                    <p className={'cms_post__excerpt'}>
                        {category?.description}
                    </p>
                </div>
            </section>
            <section className={'cms_post_list'}>
                <div className={'container !pt-0 py-8'}>
                    <Breadcrumbs crumbs={[
                        {path: process.env.REACT_APP_URL, label: 'Home'},
                        {path: process.env.REACT_APP_URL+'/blog', label: 'Blog'},
                        {path: process.env.REACT_APP_URL+`/blog/${category?.slug}`, label: category?.name},
                    ]}/>
                </div>
                <div className={'container'}>
                    <div className={'column  max-w-full md:max-w-[25%] order-2 md:order-1'}>
                        <h3 className={'cms_post_list_sidebar_title'}>Categories</h3>
                        <Loading isLoading={cats.length === 0} />
                        <ul className={'cms_post_list_sidebar_list'}>
                            {cats?.length > 0 && cats.map((cat, index) => (
                                <li id={"category_" + cat?.id + `_${index}`} key={"category_" + cat?.id + `_${index}`}>
                                    <Link to={`/blog/${cat.slug}`} title={cat.name}>
                                        {cat.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={'column'}>
                        <BlogPostList id={category?.id} setCats={setCats} setPagination={setPagination}/>
                    </div>

                </div>
                <div className={'container flex flex-1 w-full'}>
                    <Pagination pagination={pagination} handlePageChange={handlePageChange}/>
                </div>
            </section>
        </div>
    )
}

export default Category