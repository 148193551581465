import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const Solutions = ({solutions = null}) => {

    const  generatePeopleText = (min, max) => {
        let people = max > 1 ? 'people' : 'person';

        if (min === max) {

            return `Total of ${max} ${people}`
        }

        return `Up to ${max} ${people}`
    }

    return (
        <section className="solutions">
            {Array.isArray(solutions) && solutions.map(solution => (
                <div className="solution" key={solution.name.replace(' ','-')}>
                    <h4 className={'solution__title'}>{solution?.name}</h4>
                    <p className={'solution__desc'}>{solution?.desc}</p>


                    {Array.isArray(solution?.options) && solution.options.map(option => (
                        <div className={'solution__option'} key={option.id}>


                            <h4 className={'solution__option__title'}>{generatePeopleText(option.min_people, option.max_people)}</h4>

                            <div className={'row mt-2 mb-2 !gap-2 !flex-row'}>
                                <div className={'size row !flex-row !gap-4 !text-sm items-center text-gray-600'}>
                                    <FontAwesomeIcon icon="fa-solid fa-person-shelter"/> {option.size} sqft
                                </div>
                                {option?.location && (
                                <div className={'size row !flex-row !gap-4 !text-sm items-center text-gray-600'}>
                                    <FontAwesomeIcon icon="fa-solid fa-stairs" /> {option.location}
                                </div>)}
                                <div className={'size row !flex-row !gap-4 !text-sm items-center text-gray-600'}>
                                    <FontAwesomeIcon icon="fa-solid fa-clock" /> {solution.access_start_time} - {solution.access_end_time}
                                </div>
                            </div>

                            <p className={'solution__option__desc'}>
                                {option.description}
                            </p>

                            <div className={'flex flex-row justify-between'}>
                                <div className={'solution__option__buy'}>
                                    <span>£{option?.price}</span> / {option.unit}
                                </div>
                                <button className={'btn-primary self-center mt-[15px]'}>Bookings coming soon</button>
                            </div>
                            <p className={'text-xs mt-[-10px]'}>{solution.minimum_duration > 0 ? solution.minimum_duration + ` ${solution.duration_unit} minimum` : ''}</p>
                        </div>
                    ))}
                </div>
            ))}
        </section>
    )

}

export default Solutions