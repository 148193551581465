import React, { useState} from "react";
import BlogPostList from "./List";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import Pagination from "./Pagination";
import Breadcrumbs from "../Breadcrumbs";
import Loading from "../Loading";
import SEO from "../../../Seo";

const PostType = () => {

    const locationData = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [cats, setCats] = useState([]);
    const [pagination, setPagination] = useState({});

    const handlePageChange = (newPage) => {
        // Update the query parameter
        setSearchParams({ p: newPage });
    };

    const {current_page, total_pages } = pagination || {}

    const title = "Explore Insights and Tips on Commercial Space Management and Utilization | Blog"
    const meta_description = "Discover expert advice, industry insights, and success stories on commercial space management, optimization, and utilization. Stay ahead of market trends with Cohesii."
    return (
        <div className={'cms__blog'}>

            <SEO
                title={title}
                description={meta_description}
                url={process.env.REACT_APP_URL + locationData.pathname}
                canonical={process.env.REACT_APP_URL + locationData.pathname}
                image={process.env.REACT_APP_URL+'/images/cohesii-blog.png'}
                imageAlt={title}
                breadcrumbs={[
                    {path: process.env.REACT_APP_URL, label: 'Home'},
                    {path: process.env.REACT_APP_URL+'/blog', label: 'Blog'},
                ]}
                nextPage={process.env.REACT_APP_URL + `/${locationData.pathname}?page=${current_page > 1 ? current_page + 1 : 1}`}
                prevPage={process.env.REACT_APP_URL + `/${locationData.pathname}?page=${current_page +1 < total_pages ? current_page - 1 : total_pages}`}
            />

            <section className={'cms_post__header'}>
                <div className={'container'}>
                    <p className={'cms_post__published'}>Cohesii Blog</p>
                    <h1 className={'cms_post__header_title'}>Explore Insights and Tips on Commercial Space Management
                        and Utilization</h1>
                    <p className={'cms_post__excerpt'}>
                        Welcome to the Cohesii Blog, your go-to resource for expert advice, industry insights, and
                        success stories on commercial space management. Whether you're a property owner, freelancer,
                        startup, or event planner, our articles cover everything you need to know to optimize, manage,
                        and profit from your commercial spaces. Dive into our wealth of knowledge and stay ahead of
                        market trends with Cohesii.
                    </p>
                </div>
            </section>
            <section className={'cms_post_list'}>

                <div className={'container !pt-0 py-8'}>
                    <Breadcrumbs crumbs={[
                        {path: process.env.REACT_APP_URL, label: 'Home'},
                        {path: process.env.REACT_APP_URL+'/blog', label: 'Blog'},
                    ]} />
                </div>

                <div className={'container'}>
                    <div className={'column max-w-full md:max-w-[25%] order-2 md:order-1'}>
                        <h3 className={'cms_post_list_sidebar_title'}>Categories</h3>
                        <Loading isLoading={cats.length === 0} />
                        <ul className={'cms_post_list_sidebar_list'}>
                            {cats?.length > 0 && cats.map((cat, index) => (
                                <li>
                                    <Link to={`/blog/${cat.slug}`} title={cat.name}>
                                        {cat.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={'column order-1'}>
                        <BlogPostList setCats={setCats} setPagination={setPagination}/>
                    </div>

                </div>
                <div className={'container flex flex-1 w-full'}>
                    <Pagination pagination={pagination} handlePageChange={handlePageChange}/>
                </div>
            </section>
        </div>
    )
}

export default PostType