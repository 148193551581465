import Legal from "../../Layouts/Legal";
import Banner from "../../Simple/Banner";
import SEO from "../../../Seo";
import {useLocation} from "react-router-dom";
import {lazy, Suspense} from "react";
import Loading from "../Loading";


const CookiePolicyContent = lazy(() => import('./Content/CookiePolicyContent'));


const CookiePolicy = () => {

    const location = useLocation();
    const content = {
        title: 'Cookie Policy',
        content: "Understand our Cookie Policy at Cohesii LTD, detailing how we use cookies and similar technologies. Learn how to manage your cookie preferences and protect your privacy online.",
        "additional-content": [],
        asset: 'pexels-pixabay-39584 (1).jpg',
        _uid: "cookie-policy-header"
    }

    const title = "Cookie Policy "
    const meta_desc = "Understand our Cookie Policy at Cohesii LTD, detailing how we use cookies and similar technologies. Learn how to manage your cookie preferences and protect your privacy online."

    let crumbs = [
        {
            'label': "Home",
            'path': process.env.REACT_APP_URL
        },
        {
            'label': title,
            'path': process.env.REACT_APP_URL + location.pathname
        },
    ]

    return (
        <>
            <SEO
                title={title}
                description={meta_desc}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={process.env.REACT_APP_URL + location.pathname}
                image={process.env.REACT_APP_URL + '/images/cohesii.png'}
                imageAlt={'Cohesii strap line and logo over a blue gradient overlay with a commercial space background.'}
                breadcrumbs={crumbs}
            />

            <Banner content={content}/>
            <Legal className={"order-2"}>
                <Suspense fallback={<Loading isLoading={true} />}>
                    <CookiePolicyContent />
                </Suspense>
            </Legal>
        </>
    )
}

export default CookiePolicy