import {Link, useLocation} from 'react-router-dom'
import Logo from '../../../assets/cohesii.svg'
import './Header.css'
import Menu from "./Menu";
import {CohesiiContentComponent} from "../../../lib";

import useScrollListener from "../../../hooks/useScrollListener";
import {useEffect, useRef, useState} from "react";
import SidebarMenu from "./SidebarMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Header = () => {

    const button = {
        type: 'button',
        data: {
            content: 'Contact',
            link: 'Contact',
            link_title: 'Contact Us',
            link_url: '/contact',
            style: 'primary',
            classname: 'header-btn'
        }
    }

    const [navClassList, setNavClassList] = useState([]);
    const [isHidden, setIsHidden] = useState(false);
    const scroll = useScrollListener();

    const menuRef = useRef(null);

    // update classList of nav on scroll
    useEffect(() => {
        const _classList = [];

        if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
            _classList.push("nav-bar--hidden");


        if (_classList.includes('nav-bar--hidden')) {
            setIsHidden(true)
        }else {
            setIsHidden(false)
        }

        setNavClassList(_classList);
    }, [scroll.y, scroll.lastY]);

    const [isMenuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => setMenuVisible(!isMenuVisible);



    return (
        <header id={'header'} className={navClassList.join(" ")}>
            <div className={'container flex flex-row gap-[20px]'}>
                <div className={'row'}>
                    <FontAwesomeIcon icon="fa-solid fa-bars"  className={'size-8 burger'} onClick={toggleMenu} />
                    <Link  to={'/'} title={'Cohesii'} className={'logo'}>
                        <img width={180} height={38} src={('/'+Logo).replace('//','/')} title={'Cohesii'} alt={'Cohesii Logo with orange gradient colour'} />
                    </Link>
                    <Menu isHidden={isHidden} ref={menuRef} />
                </div>
                <div className={'row justify-end'}>
                    <ul className={'secondary-menu'}>
                        <li>
                            <Link to={'/list-a-space'} title={'List a space'} className={'group'}>
                                List a space
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.REACT_APP_BASE_PORTAL}auth/login`} title={'Spaces Hub'} className={'group'}>
                                Login
                            </Link>
                        </li>
                    </ul>
                    <CohesiiContentComponent content={button}/>
                </div>
            </div>

            <SidebarMenu menuRef={menuRef} toggleMenu={toggleMenu} isMenuVisible={isMenuVisible} />
        </header>
    )

}
export default Header