import {Outlet, useLocation} from "react-router-dom";
import {FooterComponent, HeaderComponent} from "../Components";
import { useEffect, useState} from "react";
import useScrollToTop from "../../hooks/useScrollTop";

import * as CookieConsent from "vanilla-cookieconsent";
import useIpAndLocation from "../../hooks/useIpAndLocation";
import ScrollToTop from "../Components/ScrollTop";
import store from "../../app/store";
import {fetchCsrfToken} from "../../app/features/csrf/csrfSlice";
import {addFasIcons} from "../../app/helpers/Icons";
import {initContentBuilder} from "../../app/helpers/ContentInit";
import Newsletter from "../Components/Newsletter/Newsletter";
function usePageTracking() {
    const location = useLocation();

    useEffect(() => {

        // Initialize gtag
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }

        if (gtag) {
            gtag('config', process.env.REACT_APP_GA_ID, {
                page_path: location.pathname + location.search,
            });
        }
    }, [location]); // Trigger on location change
}


store.dispatch(fetchCsrfToken());
addFasIcons();
initContentBuilder();

const Root = ({className}) => {

    const [initialized, setInitialized] = useState(false);
    useScrollToTop()
    usePageTracking()

    const {storedLocation, storedIp} = useIpAndLocation()

    useEffect(() => {
        // Initialize the dataLayer if not already present
        window.dataLayer = window.dataLayer || [];

        // Google Consent Mode v2 default values
        function gtag() {
            window.dataLayer.push(arguments);
        }

        const marketingConsent = CookieConsent.acceptedCategory('marketing');
        const statisticsConsent = CookieConsent.acceptedCategory('statistics');
        const preferencesConsent = CookieConsent.acceptedCategory('preferences');

        gtag('consent', 'default', {
            'ad_storage': marketingConsent === false ? 'denied' : 'granted',
            'ad_user_data': marketingConsent === false ? 'denied' : 'granted',
            'ad_personalization': marketingConsent === false ? 'denied' : 'granted',
            'analytics_storage': statisticsConsent === false ? 'denied' : 'granted',
            'functionality_storage': preferencesConsent === false ? 'denied' : 'granted',
            'personalization_storage': preferencesConsent === false ? 'denied' : 'granted',
            'security_storage': 'granted',
            wait_for_update: 500,
        });
        // Optional: Set additional configurations like redaction for ads data
        gtag("set", "ads_data_redaction", true);

        // Function to load GTM script
        const loadGTMScript = () => {
            const script = document.createElement('script');
            script.innerHTML = `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');
            `;


            // Append the script to the head of the document
            document.head.appendChild(script);
        };

        // Wait for the window to load before executing GTM
        window.addEventListener('load', loadGTMScript);

        // Clean up the event listener to prevent memory leaks
        return () => {
            window.removeEventListener('load', loadGTMScript);
        };
    }, []);

    return (
        <div className="root">
            <HeaderComponent/>
            <main className={className}>
                <div className={'wrapper flex flex-col'}>
                     <Outlet />
                </div>
                <ScrollToTop />
            </main>
            <Newsletter />
            <FooterComponent ip={storedIp} location={storedLocation}/>
        </div>
    )

}

export default Root