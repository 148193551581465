import React, {useState, useContext, createContext, useCallback,  } from 'react';
import Modal from "./Modal";

const ModalContext = createContext();

export const useModal = () => {
    return useContext(ModalContext);
};



export const ModalProvider = ({ children }) => {
    const [isShowing, setIsShowing] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const showModal = useCallback((content) => {
        setModalContent(() => content);
        setIsShowing(true);
    }, []);

    const hideModal = useCallback(() => {
        setIsShowing(false);
        setModalContent(null);
    }, []);

    return (
        <ModalContext.Provider value={{ showModal, hideModal, isShowing }}>
            {children}
                <Modal isShowing={isShowing} hide={hideModal}>
                    {modalContent}
                </Modal>
        </ModalContext.Provider>
    );
};