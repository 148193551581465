import { useEffect, useRef, useState } from 'react';

const useCarousel = (itemsCount, visibleItemsCount) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemWidth, setItemWidth] = useState(0);
    const carouselRef = useRef(null);

    useEffect(() => {

        const calculateItemDimensions = () => {
            let visibleCount = window.innerWidth < 1024 ? 2 : visibleItemsCount
            if (carouselRef.current) {
                const containerWidth = carouselRef.current.offsetWidth;
                const width = (containerWidth / visibleCount) - 8;

                for (let items of carouselRef.current.children[0].children) {
                    items.style.minWidth = `${width}px`;
                }

                setItemWidth(width)
            }
        }

        calculateItemDimensions();

        // Recalculate dimensions on window resize
        window.addEventListener('resize', calculateItemDimensions);

    }, [carouselRef.current]);

    useEffect(() => {
        if (carouselRef.current) {
            const offset = -currentIndex * (itemWidth + 10);
            carouselRef.current.style.transform = `translateX(${offset}px)`;
            carouselRef.current.style.transition = 'transform 0.3s ease-out';
        }
    }, [currentIndex]);

    const next = () => {
        let visibleCount = window.innerWidth < 1024 ? 2 : visibleItemsCount
        if (currentIndex < itemsCount - visibleCount) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return {carouselRef, next, prev}
};

export default useCarousel;