
let componentsMap = {};
let typeMap = {};
let apiKey = "";

export const setComponents = (newComponentsMap) => {
    componentsMap = newComponentsMap;
    return componentsMap;
};

export const getComponent = (componentKey) => {
    if (!componentsMap[componentKey]) {
        console.error(`Component ${componentKey} doesn't exist.`);
        return false;
    }

    return componentsMap[componentKey];
};


export const contentBuilderInit = async (options) => {
    componentsMap = options.components || {}
    typeMap = options.types || {}
    apiKey = options.apiKey
}

export { default as CohesiiContentComponent } from "./content-component";