import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_ANALYTICS || 'http://localhost:8181';

const analyticsHelper = {
    // Track a session for a user
    async trackSession(payload) {
        try {
            const response = await axios.post(`${API_BASE_URL}/track/session`, payload);

            return response.data;
        } catch (error) {
            throw error;
        }
    },

    // Track an event within a session
    async trackEvent(sessionId, eventType, additionalData = {}) {
        try {
            const response = await axios.post(`${API_BASE_URL}/track/event`, {
                session_id: sessionId,
                event_type: eventType,
                timestamp: new Date(), // Automatically set timestamp
                additional_data: additionalData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default analyticsHelper;