import {Link} from "react-router-dom";

const Legal = ({className, children}) => {

    return (

        <section className={`${className} row container cms-legal `}>

            <div className={'column max-w-full md:max-w-[20%]'}>
                <h3 className={'cms_post_list_sidebar_title'}>Legal Pages</h3>
                <ul className={'cms_post_list_sidebar_list !pl-0'}>
                    <li>
                        <Link to={'/privacy-policy'} title={'Privacy Policy'}>Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to={'/cookie-policy'} title={'Cookie Policy'}>Cookie Policy</Link>
                    </li>
                    <li>
                        <Link to={'/anti-slavery-policy'} title={'Anti Slavery Policy'}>Modern Slavery Policy</Link>
                    </li>
                    <li>
                        <Link to={'/terms-and-conditions'} title={'Terms and conditions'}>Terms and conditions</Link>
                    </li>
                </ul>
            </div>
            <div className={'column max-w-full'}>
                {children}
            </div>
        </section>
    )
}

export default Legal