import React, {forwardRef, useCallback, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import LatestListWidget from "../Blog/LatestListWidget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ResourcesMegaMenu = forwardRef(({ isHidden, isOpen, onToggle }, ref) => {

    const menuRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            if (isOpen) {
                onToggle();
            }
        }
    }, [menuRef, onToggle]);

    useEffect(() => {
        if (isHidden && isOpen) {
            onToggle();
        }
    }, [isHidden, isOpen, onToggle]);



    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onToggle();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
            <li  ref={menuRef}>
                <span
                    className="group cursor-pointer"
                    onClick={onToggle}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    role="button"
                    aria-expanded={isOpen}
                >
                    Resources
                    {!isOpen && <FontAwesomeIcon icon="fa-solid fa-chevron-down"  className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                    {isOpen && <FontAwesomeIcon icon="fa-solid fa-chevron-up"  className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                </span>

                {isOpen && (
                    <div className="mega-menu_dropdown_container">
                        <div className="container mx-auto p-0  md:p-4">
                            <div className="mega-menu__column_container">
                                <div className={'mega-menu__column col-span-2'}>
                                    <div>
                                        <h3 className={'title !text-lg !pl-0'}>Cohesii</h3>
                                        <div className={'row flex-wrap !gap-0 mt-2'}>
                                            <Link to={'/about-us'} title={'About Cohesii'}
                                                  className={'link min-w-[45%]'}>
                                                About Us
                                            </Link>
                                            <Link to={'/our-mission'} title={'Our Mission'}
                                                  className={'link min-w-[45%]'}>
                                                Our Mission
                                            </Link>
                                            <Link to={'/blog'} title={'Blog'} className={'link min-w-[45%]'}>
                                                Blog
                                            </Link>
                                            <Link to={'/contact'} title={'Contact Us'} className={'link min-w-[45%]'}>
                                                Contact Us
                                            </Link>

                                        </div>
                                    </div>
                                    <div className={'mt-4'}>
                                        <h3 className={'title !text-lg !pl-0'}>Guides</h3>
                                        <p className={'!text-lg mt-4 text-gray-500'}>
                                            Keep posted for our latest guides to finding and making the most out of available spaces.
                                        </p>
                                    </div>

                                </div>
                                <div className={'mega-menu__column col-span-4'}>
                                    <h3 className={'title !text-lg !pl-0'}>Latest Blogs</h3>
                                    <LatestListWidget limit={2} />
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </li>
    );
});

