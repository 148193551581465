import React from "react";

const MediaGallery = ({ data, baseUrl, blockRef, onClick }) => {
    return (
        <section id={'gallery'} className={'full-gallery'} ref={blockRef}>
            <h3 className={'full_gallery__title'}>Gallery</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {
                    data.media.map((path, index) => {
                        return (
                            <div key={index}><img loading={"lazy"} onClick={() => onClick(index)} title={data.name} src={`${baseUrl}${path}`}
                                                  className={'w-full min-h-full object-cover object-center rounded-md'}
                                                  alt={data.name}/></div>
                        )
                    })
                }
            </div>
        </section>
    );
};

export default  MediaGallery