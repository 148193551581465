import Menu from "./Menu";
import {Link} from "react-router-dom";
import React from "react";

const SidebarMenu = ({menuRef, toggleMenu, isMenuVisible = false}) => {


    return (
        <>
            {/* Overlay to close the menu when clicking outside */}
            {isMenuVisible && <div className="menu-overlay overflow-hidden" onClick={toggleMenu}></div>}

            {/* Sidebar Menu */}
            <div className={`sidebar-menu ${isMenuVisible ? 'visible' : ''}`}>
                <div className="menu-close" onClick={toggleMenu}>X</div>
                <Menu isHidden={false} ref={menuRef} />
                <ul className="secondary-menu">
                    <li>
                        <Link to={'/list-a-space'} title={'List a space'} className={'group'}>List a space</Link>
                    </li>
                    <li>
                        <Link to={`${process.env.REACT_APP_BASE_PORTAL}auth/login`} title={'Spaces Hub'} className={'group'}>Login</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default SidebarMenu