import * as CookieConsent from "vanilla-cookieconsent";
import './Consent.css'
import {useEffect} from "react";

const CookieConsentComponent = ({children}) => {

    // Function to initialize or update gtag consent settings
    const updateGtagConsent = () => {
        window.dataLayer = window.dataLayer || [];

        // Define gtag function if not already defined
        function gtag() {
            window.dataLayer.push(arguments);
        }

        // Update Google Consent Mode based on the cookie consent state
        gtag('consent', 'update', {
            'ad_storage': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'ad_user_data': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'ad_personalization': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'analytics_storage': CookieConsent.acceptedCategory('statistics') ? 'granted' : 'denied',
            'functionality_storage': CookieConsent.acceptedCategory('preferences') ? 'granted' : 'denied',
            'personalization_storage': CookieConsent.acceptedCategory('preferences') ? 'granted' : 'denied',
        });
    }

    useEffect(() => {
        // Initialize the CookieConsent plugin
        CookieConsent.run({
            onFirstConsent: () => {
                // Ensure the consent update runs the first time a user interacts with the consent
                updateGtagConsent();
            },
            onConsent: () => {
                // Update the consent every time a user makes a selection
                updateGtagConsent();
            },
            onChange: ({ changedCategories }) => {
                // Update consent if the user changes preferences
                updateGtagConsent();
            },
            categories: {
                necessary: {
                    enabled: true,  // Always enabled
                    readOnly: true  // Cannot be disabled
                },
                preferences: {
                    enabled: true,
                    readOnly: false,
                },
                statistics: {
                    enabled: true,
                    readOnly: false,
                    // Auto-clear cookies for statistics when opted out
                    autoClear: {
                        cookies: [
                            { name: /^_ga/ },   // Clear all cookies starting with _ga
                            { name: '_gid' },   // Clear _gid cookie
                        ]
                    }
                },
                marketing: {
                    enabled: true,
                    readOnly: false,
                },
            },
            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'Cookies',
                            description: 'We use cookies to help us improve your site experience. Read more about our <a href="/legal/cookie-policy">Cookies Policy</a>.',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage preferences'
                        },
                        preferencesModal: {
                            title: 'Your cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            closeIconLabel: 'Close',
                            sections: [
                                {
                                    description: 'Cookies are text files that are placed on your device by web sites that you visit or by clicking on a link to a site within an email. They are used for website functionality as well as to provide information to website owners. For more information, and to change your cookie preferences at any time, please see our <a href="/legal/cookie-policy">Cookies Policy</a>.'
                                },
                                {
                                    title: 'Necessary',
                                    description: 'These cookies are essential for the proper functioning and security of the website, and cannot be disabled.',
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Preferences',
                                    description: 'Preference cookies are used to remember user choices that change the way the website looks or behaves.',
                                    linkedCategory: 'preferences'
                                },
                                {
                                    title: 'Statistics',
                                    description: 'Statistics cookies help us to understand how visitors interact with our website by collecting and reporting information anonymously.',
                                    linkedCategory: 'statistics'
                                },
                                {
                                    title: 'Marketing',
                                    description: 'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user.',
                                    linkedCategory: 'marketing'
                                }
                            ]
                        }
                    }
                }
            },
        });

        // Run the consent update immediately on page load to set the initial state
        updateGtagConsent();

    }, []);

    return (
        <>
            {children}
        </>
    )
}

export default CookieConsentComponent;