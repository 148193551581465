import React, {useEffect} from 'react';
import  {createPortal} from 'react-dom';
import './Modal.css'; // Add your styles here

const Modal = ({ isShowing, hide, children }) =>  {

    useEffect(() => {
        // Add overflow-hidden to html when modal is open
        if (isShowing) {
            document.documentElement.classList.add('overflow-hidden');
        } else {
            document.documentElement.classList.remove('overflow-hidden');
        }
        // Clean up on unmount
        return () => {
            document.documentElement.classList.remove('overflow-hidden');
        };
    }, [isShowing]);


    return isShowing ? createPortal(
        <>
            <div className="modal-overlay"/>
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal">
                    <div className="modal-header">
                        <button type="button" className="modal-close-button" onClick={hide}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-content">
                        {children}
                    </div>
                </div>
            </div>
        </>, document.body
    ) : null;
}

export default Modal;