import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useLoadFeaturedCats} from "../../../hooks/useBaseData";

const SpaceActivityWidget = ({ content }) => {
    const { _uid, style, title, strapline } = content;

    const { featuredActivity: catData, isLoading: catIsLoading } = useLoadFeaturedCats();

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);

    // Handle automatic image swap
    useEffect(() => {
        if (isHovered || !catData) return; // Stop swapping if hovered or no data

        const interval = setInterval(() => {
            // Trigger fade out, then change the image
            setIsFadingOut(true);

            // After fade-out, immediately change the image
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % catData.length);
                setIsFadingOut(false); // Start fade in after the image change
            }, 300); // This matches the CSS transition duration

        }, 4000); // Change every 4 seconds

        return () => clearInterval(interval);
    }, [isHovered, catData]);

    // Handle image swap on hover without delay
    const handleMouseEnter = (index) => {
        setIsHovered(true); // Stop auto-swapping
        setIsFadingOut(true); // Start fade out

        // Immediately set the new image
        setTimeout(() => {
            setCurrentImageIndex(index);
            setIsFadingOut(false); // Fade in the new image
        }, 300); // Matches the CSS transition duration
    };

    const handleMouseLeave = () => {
        setIsHovered(false); // Resume auto-swapping
    };

    const url = process.env.REACT_APP_STORAGE_URL;

    return (

            <section id={_uid} className={`activities ${style || ""} h-full flex flex-col`}>
                <div className={'container row text-white'}>
                    <div className={'column min-w-full md:min-w-[50%] xl:min-w-[60%] pr-0 md:pr-[40px] xl:pr-[80px]'}>
                        <h2 id="" className="title">{title}</h2>
                        <p className={'under-strapline'}>{strapline}</p>

                        <div className={'acivity__items'}>
                            {!catIsLoading && catData && catData.map((item, index) => (
                                <div
                                    key={'activity_item_' + index}
                                    className="acivity__item relative group"
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <Link to={`/spaces/activities/${item?.slug}`} title={item?.name}>
                                        {item?.name}
                                        <span
                                            className="absolute right-2 top-1/2 transform -translate-y-1/2 transition-all duration-300 ease-out opacity-0 translate-x-2 group-hover:translate-x-0 group-hover:opacity-100"
                                        >
                                            <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                </div>
                            ))}
                        </div>

                        <Link to={'/spaces/activities'} title={'All space activities'} className={'btn-primary mt-6 !text-xl'}>
                            Space Activities
                        </Link>
                    </div>
                    <div className={'column hide-mob h-[600px] xl:min-w-[600px]'}>
                        {!catIsLoading && catData &&
                            <figure
                                className={`relative flex flex-1 transition-opacity duration-300 ${isFadingOut ? 'opacity-0' : 'opacity-100'}`}
                            >
                                <img
                                    loading={"lazy"}
                                    src={url + catData[currentImageIndex]?.image_path}
                                    alt={catData[currentImageIndex]?.name}
                                    title={catData[currentImageIndex]?.description}
                                    className="w-full h-auto border-2 border-blue-100 shadow-lg"
                                />

                                <div className="absolute inset-y-0 right-[-2px] pb-[10px] flex items-end">
                                    <span className="bg-blue-800 rounded-t-md px-6 py-1 transform rotate-90 origin-bottom-right text-lg font-semibold text-white">
                                        {catData[currentImageIndex]?.name}
                                    </span>
                                </div>
                            </figure>
                        }
                    </div>
                </div>
            </section>

    );
};


export default SpaceActivityWidget