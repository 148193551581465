import './Blog.css'
import {useLoaderData} from "react-router-dom";
import Post from "./Post";

import Category from "./Category";

const Blog = () => {

    const data = useLoaderData()
    const {type, data: content} = data


    return (
        <div className={'cms__blog'}>
            {type === 'post' &&
                <>
                    <Post data={content} />
                </>
            }
            {type === 'category' &&
                <>
                    <Category category={content} />
                </>
            }
        </div>
    )
}

export default Blog