import React from 'react';
import { Helmet } from 'react-helmet-async';
import Logo from "./assets/cohesii.svg";

const SEO = ({
                 title,
                 description,
                 url,
                 canonical = null,
                 image,
                 imageAlt = 'Image description',
                 titlePrefix = 'Cohesii | ',
                 prevPage = null,
                 nextPage = null,
                 breadcrumbs = [],
                 publishDate = null,
                 authorName = "Cohesii",
                 pageType = 'website', // Default is 'website', but can be 'article'
             }) => {

    const isArticle = pageType === "article";

    const schemaOrgJSONLD = isArticle
        ? {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": url
            },
            "headline": title,
            "description": description,
            "image": {
                "@type": "ImageObject",
                "url": image,
                "alt": imageAlt
            },
            "datePublished": publishDate,
            "author": {
                "@type": "Person",
                "name": authorName
            },
            "publisher": {
                "@type": "Organization",
                "name": "Cohesii",
                "logo": {
                    "@type": "ImageObject",
                    "url": "/path/to/logo.jpg"
                }
            }
        }
        : {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Cohesii",
            "url": url,
            "image": {
                "@type": "ImageObject",
                "url": image,
                "alt": imageAlt
            },
            "description": description
        };

    const robots = process.env.REACT_APP_ROBOTS

    const schemaDataOrg = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Cohesii",
        "url": process.env.REACT_URL,
        "logo": Logo,
        "contactPoint": {
            "@type": "ContactPoint",
            "email": "support@cohesii.com",
            "contactType": "Customer Service"
        },
        "sameAs": [
            "https://x.com/CohesiiLtd",
            "https://www.linkedin.com/company/cohesii/"
        ]
    };

    return (
        <Helmet
            defer={false}
            encodeSpecialCharacters={true}
        >
            {/* HTML Language */}
            <html lang="en"/>

            {/* Base Element */}
            <base target="_blank" href={process.env.REACT_URL}/>

            {/* Title with attributes */}
            <title itemProp="name" lang="en">
                {titlePrefix} {title ?? 'Spaces that fit your life'}
            </title>

            {/* Meta Description */}
            <meta name="description" content={description}/>
            <meta name="robots" content={robots}/>

            <link rel="canonical" href={`${canonical ?? url}`}/>
            {/* Open Graph Tags */}
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:type" content={isArticle ? 'article' : 'website'}/>
            <meta property="og:url" content={url}/>
            <meta property="og:image" content={image}/>
            <meta property="og:image:alt" content={imageAlt}/>
            <meta property="og:site_name" content="Cohesii"/>

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={image}/>
            <meta name="twitter:image:alt" content={imageAlt}/>

            <meta name="author" content="Cohesii"/>

            {/* Pagination Links */}
            {prevPage && <link rel="prev" href={prevPage}/>}
            {nextPage && <link rel="next" href={nextPage}/>}

            {/* JSON-LD Schema for Website/Article */}
            <script type="application/ld+json">
                {JSON.stringify(schemaOrgJSONLD)}
            </script>

            <script type="application/ld+json">
                {JSON.stringify(schemaDataOrg)}
            </script>

            {/* Rich Snippets for Breadcrumbs */}
            {breadcrumbs.length > 0 && (
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": breadcrumbs.map((crumb, index) => ({
                            "@type": "ListItem",
                            "position": index + 1,
                            "name": crumb.label,
                            "item": crumb.path ?? ""
                        }))
                    })}
                </script>
            )}
        </Helmet>
    );
};

export default SEO;