
import useCarousel from "../../../hooks/useCarousel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CategoryFilter = ({baseUrl, catData = []  , selected=false, handleFilter = (e, name) => {}}) => {


    const visibleItemsCount = 5; // Number of visible items at a time
    const {carouselRef, next, prev} = useCarousel(catData?.length || 0, visibleItemsCount);


    return (
        <>
            {catData && catData.length > 0 && (
                <section className={'container !py-0'}>
                    <h3 className={'spaces__filter-title'}>What kind of space are you looking for?</h3>
                    <div className={'spaces-categories group'}>
                        <button onClick={prev} className="carousel-button carousel-button--prev rounded-sm">
                            <FontAwesomeIcon icon="fa-solid fa-chevron-left" className="size-8 text-white" />
                        </button>
                        <div
                            ref={carouselRef}
                            className="carousel-wrapper"// Reference to the container
                        >
                            <div className="carousel">
                                {catData.map((item, index) => (
                                    <div
                                        className={`spaces-category__item relative ${selected === item.name ? 'selected' : ''}`}
                                        key={item.id}
                                        onClick={(e) => handleFilter('activity', item.name)}
                                    >
                                        <img
                                            src={`${baseUrl}${item.image_path}`}
                                            alt={item.name}
                                            loading={"lazy"}
                                            className={'space-category__image'}
                                            title={item.name}
                                        />
                                        <h4 className={'spaces-category__item_title'}>{item.name}</h4>
                                        {selected === item.name && (
                                            <FontAwesomeIcon icon="fa-solid fa-circle-check"
                                                className={'absolute top-2 bg-white rounded-full right-2 size-5 text-blaze-500'}/>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button onClick={next} className="carousel-button carousel-button--next rounded-sm ">
                            <FontAwesomeIcon icon="fa-solid fa-chevron-right"  className="size-8 text-white"/>
                        </button>
                    </div>
                </section>
            )}
        </>
    );
}

export default CategoryFilter